@import 'assets/styles/variables';

nav {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  z-index: -10;
  display: flex;
  opacity: 0;
  background: #0E0E0E;
}

:global(.light) nav {
  background: #efefef;
}

:global(nav.closed) {
  animation-name: fadeOut;
  animation-fill-mode: both;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-delay: 0.4s;
}

:global(nav.opened) {
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

.menuList {
  margin: 5vh 15%;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80vh;
  max-height: 700px;
}

.colorModeToggle {
  position: absolute;
  right: 85px;
  width: 20px;
  height: 20px;
  top: 35px;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  transition: transform .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  

  &:hover {
    transform: scale(1.2);
  }

  svg {
    font-size: 25px;
    color: $colour-light;

    :global(.light) & {
      color: $colour-dark;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    z-index: -10;
    pointer-events: none;
  }
  to {
    opacity: 1;
    z-index: 10;
    pointer-events: initial;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    z-index: 10;
    pointer-events: initial;
  }
  to {
    opacity: 0;
    z-index: -10;
    pointer-events: none;
  }
}


/* Mobiles */
@media (max-width: 576px) {
  .menuList {
    margin: 0 20%;
    height: 70vh;
  }
}