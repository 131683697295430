@import 'assets/styles/variables';

.timelineSubRow {
  margin: 25px 15px;

  .head {
    margin: 0 0 5px 0;
  }

  h4 {
    margin: 0;
    font-weight: 400;
    font-family: 'Romie', serif;
    font-size: 18px;
  }

  h5 {
    margin: 0;
    font-weight: 400;
    color: #888888;
  }

  p {
    font-weight: 200;
    font-size: 16px;
    margin: 5px 0;
  }
}

.description {
  ul {
    list-style: unset;
    padding-left: 20px;
    margin-top: 10px;

    li {
      font-weight: 200;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

.projectLink {
  display: flex;
  align-items: center;
  font-weight: 200;
  align-self: start;
  font-size: 15px;
  color: #888888;
  margin-top: 15px;

  &:hover,
  &:active,
  &:focus {
    color: $colour-light;

    svg {
      transform: translateX(10px);
    }
  }

  :global(.light) &:hover,
  :global(.light) &:active,
  :global(.light) &:focus {
    color: #141414;
  }

  svg {
    font-size: 20px;
    margin-left: 15px;
    transition: .3s transform ease-out;
  }
}

/* Medium */
@media (max-width: $breakpoint-medium) { 
  .timelineSubRow p {
    margin: 10px 0;
  }
}