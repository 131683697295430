@import 'assets/styles/variables';

.projectRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  outline: none;
  text-align: left;
  background: none;
  border: none;
  color: inherit;
  font-family: inherit;
  transition: min-height .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    min-height: 250px;
  }

  .titleRow {
    font-family: 'Romie', serif;
    font-size: 28px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  h3 {
    font-weight: 200;
    margin: 0;
    width: max-content;
    position: relative;
    max-width: 100%;
    z-index: 1;
  }

  p {
    margin: 0;
    font-weight: 200;
    text-overflow: ellipsis;
    margin-bottom: 2px;
    position: relative;
    max-width: 100%;
    width: max-content;
    z-index: 1;
  }

  a {
    z-index: 1;
    position: relative;
    width: max-content;
    font-size: 14px;
  }

  h3:after,
  p:after,
  a:after {
    content: '';
    position: absolute;
    width: calc(100% + 10px);
    height: 100%;
    left: -5px;
    top: 0;
    z-index: -1;
    background: #141414;
  }

  :global(.light) & h3:after,
  :global(.light) & p:after,
  :global(.light) & a:after {
    background-color: $colour-light;
  }

  .skillList {
    margin: 0 15px;
    flex: 1;
    position: relative;
    z-index: 1;
  }

  .projectImage {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    z-index: 0;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    background-position: top;
  }

  &:hover .projectImage,
  &:active .projectImage,
  &:focus .projectImage {
    opacity: 1;
  }

  :global(.simpleLink) {
    display: inline-flex;
    align-items: center;

    &:last-of-type:not(:first-of-type) {

      margin-left: 20px;
    }
    svg {
      margin-left: 5px;
    }
  }
}

.projectContainer {
  margin: 0 10%;
}

/* Tablets */
@media (max-width: 768px) {
  .projectRow {
    margin: 0;
  }

  .projectRow .titleRow {
    display: block;
  }

  .skillList {
    display: none;
  }
}