.employmentLogo {
  margin: 5vh auto;
  margin-bottom: -15px;
  display: flex;
  justify-content: center;

  img {
    max-width: 350px;
    width: 80%;
  }
}

.detailsSection {
  padding-top: 0;
}

.detailsContainer {
  display: flex;
  gap: 10%;
}

.description {
  font-weight: 200;

  ul, ol {
    list-style: initial;
    padding-left: 20px;
    margin-top: 10px;

    li {
      font-weight: 200;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

.detailsColumn {
  flex: 1;
}

.projectMetric {
  h3 {
    font-family: 'Romie', serif;
    font-size: 22px;
    font-weight: 200;
    margin-bottom: 10px;
    margin-top: 16px;
  }
}

.responsibilitiesList {
  ul, ol {
    list-style: initial;
    padding-left: 20px;
    font-weight: 200;
  
    li {
      margin-bottom: 8px;
    }
  }
}

.backButton {
  margin: 0px auto;
  width: 75%;
  padding-top: 40px;
}

.skillsList {
  text-align: center;
  margin-top: -30px;
  margin-bottom: 25px;
}

/* Mobiles */
@media (max-width: 576px) {
  .detailsContainer {
    display: initial;
    padding: 0px 40px;
  }
}