@import 'assets/styles/variables';

.fieldGroup {
  margin: 30px 0;

  label {
    display: block;
    font-size: 20px;
    font-weight: 400;
  }

  input,
  textarea {
    width: 100%;
    font-size: 18px;
    padding: 12px 15px;
    border: none;
    outline: none;
    margin: 5px 0;
    box-sizing: border-box;
    background: $colour-dark;
    font-weight: 200;
    font-family: 'Poppins', sans-serif;
    color: $colour-light;
    border: 2px solid transparent;
    transition: all .3s ease-in-out;
    resize: none;  
    -webkit-appearance: none;
  }

  :global(.light) input,
  :global(.light) textarea {
    background-color: #888888;
  }

  input:active,
  input:focus,
  textarea:active,
  textarea:focus {
    background: #555555;
    border: 2px solid $colour-light;
  }

  :global(.light) input:active,
  :global(.light) input:focus,
  :global(.light) textarea:active,
  :global(.light) textarea:focus {
    border: 2px solid #141414;
  }

  input:hover,
  textarea:hover {
    background: #555555;
  }

  input[type=submit] {
    font-weight: 400;
    width: 40%;
    min-width: 150px;
    background: $colour-dark;
    -webkit-appearance: none;
    text-transform: lowercase;
  }

  :global(.light) input[type=submit] {
    background: #888888;
  }

  input[type=submit]:hover {
    border: 2px solid $colour-light;
    background: #555555;
  }

  :global(.light) input[type=submit]:hover {
    border: 2px solid #141414;
  }
}
