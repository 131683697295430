@import 'assets/styles/variables';

.container {
  position: relative;
}

:global(.dark).container {
  color: $colour-light;
  background: $colour-background-dark;
}

:global(.light).container {
  color: #141414;
  background: $colour-background-light;
}