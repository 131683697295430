/* COLOURS */
$colour-dark: #444;
$colour-light: #FFF;

$colour-cursor-light: $colour-dark;
$colour-cursor-dark: rgba(254, 254, 254, 0.808);

$colour-background-light: #FFFFFF;
$colour-background-dark: #141414;


/* BREAKPOINTS */
$breakpoint-medium: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;