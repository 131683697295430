@import 'assets/styles/variables';

.timelineRow {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
  margin: 10px 0;
  width: 100%;
}

.timelineDate {
	text-align: center;
	color: #888888;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    left: 50%;
    background: #888888;
    bottom: 0;
    top: 40px;
  }
}

.timelineContainer {
  display: flex;
  margin: 30px 0;
}

.timelineHead {
  text-align: right;
	width: 40%;

  img {
    width: 250px;
  }
}

.timelineTitle {
  font-weight: 200;
  font-size: 18px;
  margin-top: 5px;
}

.timelineSubTitle {
  font-weight: 200;
  font-size: 15px;
  margin-bottom: 10px;
  color: #888888;
  white-space: nowrap;
}

.timelineText {
	margin-left: auto;
	width: 40%;

  p {
    font-weight: 200;
    font-size: 18px;
    margin: 0;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.skillList li {
  margin: 5px 0 3px 8px;
}

/* Medium */
@media (max-width: $breakpoint-medium) { 
  .timelineDate {
    font-size: 18px;

    &:after {
      content: none;
    }
  }

  .timelineContainer {
    display: block;
  }

  .timelineHead {
    text-align: center;
    width: auto;
    margin: 15px 0;

    img {
      width: 100px;
    }
  }

	.timelineText {
    align-self: center;
    width: 100%;
  }

  .skillList li {
    margin: 5px 8px 3px 0px;
  }
}

/* Mobiles */
@media (max-width: $breakpoint-mobile) {
  .timelineRow {
    width: 85%;
    margin: auto;
  }
}