@import 'assets/styles/variables';

.cursorRing {
  position: fixed;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  border: 2px solid $colour-cursor-dark;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
  /*NOTE: had to remove mix-blend-mode and it's very resource intensive and caused cursor lag*/
  /*mix-blend-mode: difference;*/

  :global(.light) & {
    border-color: $colour-cursor-light;
  }
  
  &.hidden {
    opacity: 0;
  }

  &.hovered {
    width: 50px;
    height: 50px;
    border-width: 3px;
    border-color: lightgray;
  }

  &.clicked {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

.cursor {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #fefefe;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  /*NOTE: had to remove mix-blend-mode and it's very resource intensive and caused cursor lag*/
  /*mix-blend-mode: difference;*/

  :global(.light) & {
    background-color: $colour-cursor-light;
  }
  
  &.hidden {
    opacity: 0;
  }

  &.hovered {
    display: none;
  }
}