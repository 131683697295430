@import 'assets/styles/variables';

.list {
  margin-bottom: 10px;
}

.listItem {
  vertical-align: middle;
  padding: 3px 8px;
  margin: 5px 8px 3px 0;
  background: $colour-dark;
  border-radius: 6px;
  display: inline-block;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 200;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;

  :global(.light) & {
    background: #888888;
    color: $colour-light;
  }
}