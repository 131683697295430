@import 'assets/styles/variables';

.icon {
  outline: none;
  display: block;
  margin: 15px 25px;
  width: 25px;
  height: 25px;
  padding: 9px;
  text-align: center;
  border: 2px solid transparent;
  background: $colour-dark;
  transition: border .2s ease-in-out;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  svg {
    font-size: 25px;
  }
}

:global(.light) .icon {
  background: #888888;
}

.icon:hover,
.icon:focus,
.icon:active {
  background: #555555;
  border: 2px solid $colour-light;
}

:global(.light) .icon:hover,
:global(.light) .icon:focus,
:global(.light) .icon:active {
  border: 2px solid #141414;
}


/* Mobiles */
@media (max-width: 576px) {
  .icon {
    margin: 15px;
  }
}