@import 'assets/styles/variables';

.container {
  width: 50%;
  max-width: 512px;
  margin: auto;
  position: relative;
}

/* Medium */
@media (max-width: $breakpoint-medium) {  
  .container {
    width: 85%;
  }
}