@import 'assets/styles/variables';

.scrollMenu {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Tablets */
@media (max-width: $breakpoint-tablet) {
  .scrollMenu {
    display: none;
  }
}