@import 'assets/styles/variables';

header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 75%;
  min-height: 100vh;
  margin: auto;
  position: relative;
  
  h1 {
    margin-top: 15vh;
    margin-bottom: 0;
    font-size: 120px;
    font-weight: 100;
  }
}

.skillLoop {
  font-family: 'Romie', serif;
  font-size: 40px;
  font-weight: 300;
  overflow: hidden;
  height: 58px;
  white-space: nowrap;
  

  li {
    /*animation: vertLoop 8s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
    animation-fill-mode: both;*/
    padding: 10px;
    color: #888888;
  }
}

.skills {
  font-weight: 400;
  margin-top: 120px;
}

.stackList h2 {
  margin: 5px 0;
  font-weight: 100;
  font-size: 16px;
  color: #888888;
}

.stackList li {
  display: inline-block;
  position: relative;
}

.stackList li p {
  position: absolute;
  width: 100%;
  margin: 0;
  opacity: 0;
  transform: translateY(-5px);
  transition: all .3s ease;
  font-weight: 200;
  font-size: 12px;
}

.stackList li a:hover ~ p,
.stackList li a:focus ~ p {
  transform: translateY(0px);
  opacity: 1;
}

@keyframes vertLoop {
  0%, 10% {
    transform: translateY(0%);
  }
  15%, 30% {
    transform: translateY(-100%);
  }
  35%, 50% {
    transform: translateY(-200%);
  }
  55%, 70% {
    transform: translateY(-300%);
  }
  75%, 90% {
    transform: translateY(-400%);
  }
  95%, 100% {
    transform: translateY(-500%);
  }
}

.nudge {
  margin-bottom: 50px;
  align-self: center;
  position: relative;
  color: #888888;
  transition: color 0.3s ease-in-out;
  outline: none;
  font-weight: 200;

  &:hover,
  &:active,
  &:focus {
    color: $colour-light;
  }

  :global(.light) &:hover,
  :global(.light) &:active,
  :global(.light) &:focus {
    color: #141414;
  }

  &:after {
    content: '';
    position: absolute;
    border: solid #888888;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 8px;
    transform: rotateZ(45deg) translate(0, 0);
    top: 100%;
    left: calc(50% - 12px);
    transition: transform 0.3s ease-in-out;
  }

  &:hover:after {
    transform: rotateZ(45deg) translate(5px, 5px);
  }
}

/* Medium */
@media (max-width: $breakpoint-medium) {  
  header h1 {
    font-size: 75px;
  }
}

/* Tablets */
@media (max-width: $breakpoint-tablet) {
  header {
    width: 85%;
  }

  .skillLoop {
    font-size: 25px;
    height: 52px;
  }
}

/* Mobiles */
@media (max-width: $breakpoint-mobile) {
  header {
    width: 90%;
  }

  header h1 {
    font-size: 60px;
  }

  .skillLoop {
    font-size: 20px;
    height: 45px;
  }
}