@import 'assets/styles/variables';

section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 5vh 0;
  width: 75%;
  max-width: 980px;
  position: relative;

  h2 {
    margin: 50px 0;
    font-size: 50px;
    font-weight: 700;
    background: $colour-light;
    color: #141414;
    padding: 5px 20px;

    &.small {
      margin-bottom: 25px;
    }
  }

  :global(.light) & h2 {
    background: #141414;
    color: $colour-light;
  }

  .subTitle {
    font-family: 'Romie', serif;
    font-size: 22px;
    margin-bottom: 25px;
    color: #888888;
    text-align: center;
  }
}

/* Tablets */
@media (max-width: $breakpoint-tablet) {
  section {
    width: 85%;
  }
}

/* Mobiles */
@media (max-width: $breakpoint-mobile) {
  section {
    width: 100%;

    .subTitle {
      margin: 0 10%;
      margin-bottom: 25px;
    }
  }
}