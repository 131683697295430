@import 'assets/styles/variables';

.notFound {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 75%;
  height: 100vh;
  margin: auto;

  h1 {
    font-size: 80px;
    text-align: center;
    font-weight: 100;
  }

  p {
    color: #888888;
    font-weight: 200;
  }
}

/* Medium */
@media (max-width: $breakpoint-medium) {  
  .notFound h1 {
    font-size: 70px;
  }
}

/* Mobiles */
@media (max-width: $breakpoint-mobile) {
  .notFound h1 {
    font-size: 45px;
  }
}