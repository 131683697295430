@import 'assets/styles/variables';

html {
  scroll-behavior: smooth;
  cursor: none;
}

body {
  margin: 0;
  background: #141414;
  color: #FFFFFF;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

::selection {
  background: #CCC;
}

a {
  text-decoration: none;
}

h3 {
  font-size: 25px;
  font-weight: 700;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
button,
label,
input,
textarea {
  cursor: none;
}

a,
button.buttonLink {
  color: $colour-light;
  transition: color .1s ease-in-out;
}

button.buttonLink {
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
}

a:hover,
a:focus,
button.buttonLink:hover,
button.buttonLink:focus {
  outline: none;
}

a.simpleLink,
button.buttonLink {
	color: #888888;
}

a.simpleLink:hover,
a.simpleLink:focus,
a.simpleLink:active,
button.buttonLink:hover,
button.buttonLink:focus,
button.buttonLink:active {
	color: $colour-light;
}

.light a.simpleLink:hover,
.light a.simpleLink:focus,
.light a.simpleLink:active,
.light button.buttonLink:hover,
.light button.buttonLink:focus,
.light button.buttonLink:active {
	color: $colour-dark;
}

.textCenter {
  text-align: center;
}

.noMargin {
  margin: 0;
}

.accordion {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.8s;
}

.accordion.open {
  max-height: 100rem;
}

.animate {
  width: 100%;
}

.inline {
  display: inline;
}

.para--light {
  font-weight: 200;
  text-align: center;
}

.para--error {
  font-weight: 200;
  text-align: center;
  color: #888888;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

@font-face {
  font-family: 'Poppins';
  src: url("assets/fonts/Poppins-Thin.ttf");
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("assets/fonts/Poppins-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url("assets/fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
  font-display: swap;
}


@font-face {
  font-family: 'Romie';
  src: url("assets/fonts/Romie-Regular.woff2");
  font-display: swap;
}