@import 'assets/styles/variables';

.menuItem {
  font-size: 55px;
  font-weight: 800;
  
  :global(nav.opened) & {
    animation-name: fadeInUp;
    animation-fill-mode: both;
    animation-duration: 0.4s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  :global(nav.closed) & {
    animation-name: fadeOutDown;
    animation-fill-mode: both;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  a {
    color: #888888;
    transition: color 0.2s ease-in-out;
    position: relative;
  }

  a:hover {
    color: $colour-light;
    text-decoration: none;
  }

  :global(.light) & a:hover {
    color: #141414;
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(25%);
  }
}

/* Mobiles */
@media (max-width: 576px) {
  .menuItem {
    font-size: 45px;
  }
}